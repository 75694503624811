<template>
  <error-template
    img-url="https://storage.googleapis.com/tillhub-api-images/dashboard/illustrations/error-pages/504.svg"
    :title="$t('pages.error.504.title')"
    :sub-title="$t('pages.error.504.subtitle')"
  />
</template>

<script>
import ErrorTemplate from './components/error-template'
export default {
  name: 'Page504',
  components: { ErrorTemplate }
}
</script>

<style scoped></style>
